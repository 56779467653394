.select_component,
.select_component-list,
.select_component-field,
.select_component-field-line {
    font-size: inherit;
}

.select_component {
    /*padding: 32px 0;*/
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: inherit;
    height: 100%;
}

.select_component-selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 9px 12px;
    border: 1px solid #E3E3E5;
    background-color: #FEFEFE;
    border-radius: 8px;
    margin-top: 6px;
    cursor: pointer;
    /*height: 46px;*/
}

.select_component-field {
    border-radius: 10px;
    box-shadow: 0 4px 18px 0 rgba(56, 50, 63, 0.1);
    isolation: isolate;
    background: rgba(254, 254, 254, 1);
    width: 100%;
    padding: 7px;
}

.select_component-list {
    visibility: hidden;
    max-height: 350px;
    overflow: scroll;
    /*background: #FEFEFE;*/
    position: absolute;
    z-index: 2;
    top: 100%;
    width: 100%;
    box-shadow: 0 4px 18px 0 rgba(56, 50, 63, 0.1);
}

.select_component-list.active {
    visibility: visible;
}

.select_component-listener {
    display: none;
}

.select_component-listener.active {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(227, 227, 229, 0);
}

.select_component-img,
.select_component-img > img {
    background-color: #FEFEFE;
    display: flex;
    align-items: center;
}

.select_component-field-line {
    font-size: inherit;
    font-weight: 400;
    padding: 12px 12px;
    background-color: rgba(254, 254, 254, 1);
    border-radius: 8px;
    cursor: pointer;
}

.select_component-field-line:hover,
.select_component-field-line.selected {
    background-color: rgba(244, 243, 245, 1);
}

.select_component-label {
    background-color: inherit;
    color: #949399;
    /*font-size: 12px;*/
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
}