.input_ticker {
    width: 100%;
}

.input_ticker-label {
    font-size: 12px;
    font-weight: 500;
    color: #949399;
    line-height: 13px;
    margin-bottom: 8px;
}

.input_ticker-wrapper {
    position: relative;
    width: 100%;
    justify-content: space-between;
}

.input_ticker-field {
    width: 100%;
    padding: 9px 40px 9px 12px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #E5E5E5;
}

.input_ticker-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}