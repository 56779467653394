.root_stock {
    flex-grow: 1;
    background-color: #fefefe;
    padding: 8px;
    margin: 10px auto;
    border-radius: 8px;
    height: calc(100vh - 40px);
    width: 100%;

}

.root_stock-select {
    display: flex;
    position: relative;
    align-items: center;
}

.root_stock-title {
    font-size: 40px;
    font-weight: 600;
    margin-left: 15px;
}

.root_stock-main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    /*display: flex;*/
    /*background-color: antiquewhite;*/
    /*justify-content: space-between;*/
}

.root_stock-summary {
    position: absolute;
    top: 20%;
    right: 0;
    width: 371px;
    height: 100%;
    /*background-color: #7E8088;*/
}

.root_stock-graph {
    width: calc(100% - 400px);
    /*background-color: #949399;*/
    height: 80%;
}

.root_stock-graph.fvt {
    width: 100%;
}

.root_stock-graph.des {
    width: 100%;
}

.root_stock-description {
    width: calc(100% - 400px);
    /*height: 600px;*/
    /*background-color: aqua;*/
}

.graph-stat {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.graph-last_date {
    font-size: 12px;
    font-weight: 400;
}

.graph-last_date > span {
    color: #7E8088;
}

.graph-price-block {
    margin-top: 8px;
    display: flex;
    align-items: flex-end;

}

.graph-price {
    font-size: 26px;
    font-weight: 600;
    margin-right: 10px;
}

.graph-dynamic {
    font-size: 16px;
    font-weight: 600;
    color: #0BC296;
}

.graph-dynamic.downgrade {
    color: #E24D56;
}

.summary-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.summary {
    position: relative;
    top: 40px;
    width: 100%;
    /*height: 450px;*/
    background-color: #F5F6FA;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    /*justify-content: space-between;*/
    padding: 20px 24px;
}

.summary-row {
    margin-bottom: 10px;
}

.summary-row_checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #949399;
    font-weight: 600;
    font-size: 14px;
}

.summary-row_checkbox label {
    margin-right: 10px;
}

.root_stock-option {
    margin-top: 20px;
    display: flex;
    width: 100%;
    border-bottom: 1px solid #E4E4EB;
}

.root_stock-option-btn {
    padding: 20px;
    color: #7E8088;
    cursor: pointer;
}

.description-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.description-row {
    width: 47%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0;
    border-bottom: 1px solid #E4E4EB;
}

.description-key {
    color: #7E8088;
    font-weight: 400;
    font-size: 16px;
}

.description-value {
    text-align: end;
}

.description-about {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 24px;
    color: #101011;
}

.graph-nav {
    display: flex;
    padding: 4px;
    font-size: 14px;
    font-weight: 400;
    background-color: #F5F6FA;
    border-radius: 6px;
}

.graph-nav-elem {
    padding: 8px 12px;
    cursor: pointer;
}

.graph-nav-elem.active {
    background-color: #FFFFFF;
    border-radius: 4px;
}

.graph-nav-cand {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    font-size: 14px;
    font-weight: 400;
    background-color: #FFFFFF;
    border-radius: 6px;
}

.graph-nav-elem-cand {
    padding: 8px 12px;
    cursor: pointer;
}

.graph-nav-elem-cand.active {
    background-color: #F5F6FA;
    border-radius: 4px;
}

.solo-option {
    font-size: 27px;
    font-weight: 500;
    margin: 45px 0;
}

.root_stock-option-btn.active {
    color: #0080BF;
    border-bottom: 2px solid #0080BF;
}

.graph {
    /*margin-top: 32px;*/
    position: relative;
    /*height: 400px;*/
    height: 100%;
}


.summary-title {
    font-weight: 400;
    font-size: 9px;
    color: #7E8088;
}

.summary-value {
    margin-top: 8px;
    font-weight: 600;
    font-size: 16px;
}

.summary-value.stonks {
    color: #0BC296;
    display: flex;
    align-items: center;
}

.summary-value.stonks.downgrade {
    color: #E24D56;
}

.summary-value > img {
    margin-right: 5px;
}

.graph-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.index_doesnt_exist {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
}

.index_doesnt_exist_link {
    color: #F5F6FA;
    cursor: pointer;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 8px;
    background-color: #0080BF;
}

.not_enough_data {
    font-weight: 600;
    font-size: 24px;
    margin: 40px 0;
}

.graph__description {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    /*justify-content: space-between;*/
    gap: 50px;
}

.des__card-wrapper {
    width: 30%;
}


@media (max-width: 1100px) {
    .graph-price-block {
        flex-direction: column;
        align-items: flex-start;
    }

    .root_stock-summary {
        position: inherit;
        margin: auto;
    }

    .root_stock-graph {
        width: 100%;
    }


    .root_stock-main {
        display: block;
        height: 100%;
        flex-direction: column;
        align-items: center;
    }

    .root_stock-description {
        width: 100%;
    }

    .root_stock-select {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 700px) {
    .description-details {

    }

    .description-row {
        width: 100%;
    }
    .des__card-wrapper {
        width: 100%;
    }

    .graph__description {
        gap: 20px;
    }
}


@media (max-width: 550px) {
    .graph-stat {
        flex-direction: column;
        align-items: center;
    }

    .graph-short-summary {
        margin: 10px 0 28px;

    }

    .graph-price-block {
        flex-direction: row;
        align-items: flex-end;
    }

    .root_stock-title .select_component-selected,
    .root_stock-title {
        font-size: 34px;
        font-weight: 600;
        line-height: 34px;
    }

    .root_stock-option {
        overflow: scroll;
    }


}