.product-detail_param-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
    height: 58px;
}

.product-detail_param-line-title {
    font-weight: 600;
}

.product-detail_param-line-value {
    /*width: 83px;*/
    font-size: 14px;
    flex: 1;

    /*flex-grow: 2;*/
}

.product-detail_param-line-value.fg {
    /*flex-grow: 2;*/
}
.product-detail_param-line-value.fg:not(:last-child) {
    /*margin-right: 16px;*/
}

.product-detail_param-line-value-large {
    width: 102px;
}

.product-detail_param_container {
    /*width: 290px;*/
    width: 380px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.product-detail_param_container-vanilla {
    display: flex;
    justify-content: flex-start;
    width: 320px;
    height: 61px;
}

.product-detail_param-line-value-vanilla {
    width: 110px;
    margin-right: 16px;
    flex-grow: 2;
}

.product-detail_param-line-value-vanilla.small-input {
    width: 45%;
    flex-grow: unset;
}

@media (max-width: 768px) {
    .product-detail_param-line {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }

    .product-detail_param_container {
        width: 100%;
    }

    .product-detail_param-line-value {
        width: 32%;
    }
}