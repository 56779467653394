.graph-list {
    background: #fefefe;
    padding: 24px 24px 0;
    border-left: 1px solid #e6e6e6;
}

.graph-table {
    overflow: hidden;
}

.graph-table-context {
    display: inline-block;
}

.graph-list-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 26px;
    font-weight: 600;
}

.graph-table-header {
    display: flex;
    height: 46px;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    color: #949399;
}

.graph-table-number-header {
    width: 85px;
    padding: 14px 12px;
    text-align: end;
}
.graph-table-ok-header  {
    width: 100px;
    padding: 14px 12px;
    text-align: end;
}
.graph-table-date-header {
    width: 90px;
    padding: 14px 8px;
    text-align: end;
}

.graph-table-number {
    width: 85px;
    padding: 12px 9px;
    text-align: end;
}

.graph-table-ok {
    width: 100px;
    padding: 12px 9px;
    text-align: end;
}

.graph-table-date {
    width: 90px;
    padding: 12px 9px;
    text-align: end;
}

.graph-table-line {
    display: flex;
    border-top: 1px solid #e6e6e6;
    font-size: 14px;
    font-weight: 400;
}

