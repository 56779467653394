.input_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;
    font-size: 12px;
    color: #949399;
    line-height: 13px;
    height: 100%;
}

.input_text-input {
    width: 100%;
    padding: 9px 12px;
    font-weight: 400;
    background-color: #FEFEFE;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1px;
    height: 100%;
}

.input_text-input--with_label {
    margin-top: 6px;
}