.fixing_details_mobile-actions {
    display: flex;
    justify-content: space-between;
}

.fixing_details_mobile-actions__item {
    width: 100%;
    text-align: center;
}

.fixing_details_mobile-details__header {
    font-size: 14px;
    font-weight: 600;
    color: #949399;
    margin: 8px 0;
}

.fixing_details_mobile-details__header,
.fixing_details_mobile-details__body-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.fixing_details_mobile-details--selector {
    width: 4%;
}
.fixing_details_mobile-details--pricing {
    width: 47%;
}


.fixing_details_mobile-details--ac {
    width: 22%;
}


.fixing_details_mobile-details--cpn {
    width: 22%;
}

@media (max-width: 768px) {
    .fixing_details_mobile {
        padding: 16px;
    }
    .fixing_details_mobile-action-btn {
        position: fixed;
        bottom: 16px;
        right: 16px;
        width: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 10;
    }
    .fixing_details_mobile-action-btn > div {
        width: 56px;
        height: 56px;
        background-color: #0080BF;
        border-radius: 50%;

    }
}