.graph_params-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;
}

.graph_params-line-label {
    width: 56px !important;
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
}

.graph_params-line-value {
    margin-right: 8px;
    width: 89px;
}

.graph_params-line-value--full {
    width: 186px;
    margin-right: 8px;
}

@media (max-width: 768px) {

    .graph_params-line-value {
        width: auto;
    }

    .graph_params-line-value--full {
        width: 100%;
    }
}