.div-graph {
    width: 100%;
    height: 100%;
    position: relative;
    /*overflow: scroll;*/
}

.close_div {
    background-color: #949399;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    right: 8px;
}

.div_window {
    background-color: #FFFFFF;
    width: 100%;
    height: calc(100% - 50px);
    margin-top: 50px;
    border-radius: 20px 20px 0 0;
    overflow: hidden;
}

.div_window-content {
    width: 100%;
    height: 100%;
    display: flex;
}

.div_window-settings {
    width: 394px;
    height: 100%;
    position: relative;
}

.div_window-graph {
    width: calc(100% - 394px);
    height: 100%;
    background-color: #40A9DD;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div_window-settings-content {
    width: 100%;
    padding: 24px 24px 60px;
    overflow: scroll;
    height: 100%;
}

.div_window-settings-content::-webkit-scrollbar {
    display: none;
}

.settings-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.settings-title {
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;
}

.settings-mode {
    height: 36px;
    display: flex;
    align-items: center;
}

.settings-mode > div:not(:last-child) {
    margin-right: 6px;
}

.settings-buttons {
    margin: 20px 0;
    height: 36px;
}

.settings-table {
    /*height: 300px;*/
}

.node_button {
    padding: 9px 16px;
    background-color: #F3F3F3;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border-radius: 50px;
}

.node_button.active {
    color: #FEFEFE;
    background-color: #0080BF;
}

.setting-button {
    padding: 9px 18px;
    font-size: 14px;
    font-weight: 600;
    /*width: 110px;*/
}

.settings-table-header {
    display: flex;
    font-weight: 600;
    font-size: 14px;
    color: #949399;
    margin-bottom: 10px;
}

.settings-state {
    width: 40px;
}

.settings-name {
    width: 94px;
    margin-right: 8px;
}

.settings-date {
    width: 96px;
    margin-right: 8px;
}

.settings-value {
    width: 85px;
    margin-right: 8px;
}
.settings-percent {
    width: 85px;
}

.settings-table-row {
    display: flex;
    height: 36px;
    margin-bottom: 10px;
    align-items: center;
}

.div-graph-content {
    width: 100%;
    height: 100%;
}

.div-graph-content > .js-plotly-plot,
.div-graph-content > div > div {
    width: 100%;
    /*height: calc(100% - 70px);*/
    height: 100%;
}

.settings-date-field {
    border: 1px solid #E3E3E6;
    height: 100%;
    background: none !important;
    padding: 9px 12px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 8px;
}

.settings-date-field-block {
    height: 100%;
}

.settings-date-field::-webkit-calendar-picker-indicator {
    display: none;
}

.div_window-settings-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 24px;
    border-top: 1px solid #E3E3E6;
    background-color: #FFFFFF;
}

.div-window-save {
    background: #0080BF;
    color: #ffffff;
    padding: 9px 20px;
    border-radius: 8px;
    line-height: 18px;
    font-weight: 600;
    margin-right: 6px;
    cursor: pointer;
}

.div-window-remove {
    background: #E7E7E9;
    color: #949399;
    padding: 9px 20px;
    border-radius: 8px;
    line-height: 18px;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 600px) {
    .div_window-content {
        flex-direction: column;
        position: relative;
        overflow: scroll;
    }

    .div_window-settings {
        position: static;
        /*position: center;*/
        /*max-height: 425px;*/
    }

    .div_window-settings-footer {
        z-index: 2;
        background-color: #FFFFFF;
        position: fixed;
    }

    .div_window-graph {
        height: 450px;
        width: 100%;
        margin-bottom: 60px;
    }

    .div_window-settings-content {
        /*max-height: 425px;*/
        padding-bottom: 5px !important;
        overflow: hidden;
    }

    .div-graph-content,
    .div-graph-content > .js-plotly-plot,
    .div-graph-content > div > div {
        width: 100vw;
        height: 100vw;
    }

    .div_window {
        margin: 0;
        border-radius: 0;
        height: 100%;
    }

    .close_div {
        background-color: #F3F3F3;
        z-index: 2;
    }
}