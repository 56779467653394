.vol__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    font-size: 14px;
    border-bottom: 1px solid #E4E4EB;
}

/*.vol__line:nth-child(odd) {*/
/*    background-color: #f5f5f5;*/
/*}*/

.vol__line.headhead {
    font-size: 12px;
    font-weight: 600;
    color: #7E8088;
}


.vol__label {
    font-weight: 600;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.vol__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px
}

.vol__card {
    /*margin: 15px 15px 30px;*/
    width: 45%;
}

.vol__line-strike {
    width: 10%;
}

.vol__line-data {
    width: 15%;
    text-align: right;
}

@media (max-width: 768px) {
    .vol__card {
        width: 100%;
    }
}
