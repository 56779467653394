.trade-details {
    margin-top: 30px;
    background-color: #FEFEFE;
    width: 408px;
    border-radius: 20px;
    margin-bottom: 24px;
    /*padding: 24px;*/
}

.trade-details > div[style] {
    margin-top: 20px;
}
.trade-details label {
    font-size: 12px;
}

.trade-detail-select {
    background-color: #FEFEFE;
}

.trade-detail-select-block {
    background-color: #FEFEFE;
}

.trade-detail-select-label {
    background-color: #FEFEFE;
    font-size: 12px;
    font-weight: 500;
    color: #949399;
}

.trade-detail-input {
    background-color: #FEFEFE;
    padding: 9px 14px;
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid #E3E3E5;
    margin-top: 6px;
}

.trade-detail-input-block {
    background-color: #FEFEFE;
    margin-top: 20px;
}

.trade-detail-input-label {
    background-color: #FEFEFE;
    font-size: 12px;
    font-weight: 500;
    color: #949399;

}

.trade-details .select_component-selected {
    font-size: 14px;
    padding: 9px 14px;
    font-weight: 400;
    margin-top: 6px;
    height: 39px;
}