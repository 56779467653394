.logo {
    /*margin-right: 20px;*/
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background: #fefefe;
    margin-right: 10px;
    /*margin-left: 10px;*/
    border-radius: 8px;
    cursor: pointer;
}

.logo > img {
    width: 65%;
}

@media (max-width: 767px) {
    .logo {
        display: none;
    }
}