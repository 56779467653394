.dvd__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    font-size: 14px;
    border-bottom: 1px solid #E4E4EB;
}

/*.dvd__line:nth-child(odd) {*/
/*    background-color: #f5f5f5;*/
/*}*/

.dvd__line.headhead {
    font-size: 12px;
    font-weight: 600;
    color: #7E8088;
}


.dvd__label {
    font-weight: 600;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.dvd__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px
}

.dvd__card {
    /*margin: 15px 15px 30px;*/
    min-width: 350px;
}

.dvd__line-date {
    width: 30%;
}

.dvd__line-amt {
    width: 30%
}

.dvd__line-quarter {
    width: 15%;
}

.dvd__line-spot {
    width: 25%;
    text-align: right;
}