.tickers_window {
    display: flex;
    flex-wrap: wrap;
    margin: 8px 0;
}

.tickers_window-ticker-remove,
.tickers_window-ticker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    border-radius: 50px;
    background-color: #F3F3F3;
    margin-right: 4px;
    margin-bottom: 4px;
    cursor: pointer;
}

.tickers_window-ticker-remove {
    background-color: #fefefe;
    border: 1px solid #F3F3F3;
}

.ticker_window-remove {
    font-size: 20px;
    margin-left: 8px;
}