.modal_window {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, 0.80);
    padding: 50px 8px 0;

    display: flex;
    justify-content: center;
    align-items: center;
}



.modal_window-close {
    position: absolute;
    top: 7px;
    right: 7px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    background-color: rgba(254, 254, 254, 0.30);
    border-radius: 50%;
    /*background: url("./../../static/close.svg") no-repeat center center;*/
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal_window-close-img {
    width: 100%;
    height: 100%;
    background: url("./../../static/cross_16_white.svg") no-repeat center center;
}

.modal_window-content {
    background-color: #fff;
    border-radius: 20px 20px 0 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.modal_window-tiny {
    width: 600px;
}

@media (max-width: 768px) {
    .modal_window {
        padding: 0;
    }
    .modal_window-content {
        border-radius: 0;
    }
    .modal_window-close {
        top: 16px;
        right: 16px;
        background-color: #F3F3F3;
        z-index: 1001;
    }
    .modal_window-close-img {
        background: url("./../../static/cross_16.svg") no-repeat center center;
    }
}