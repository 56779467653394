.fixing_details {
    width: 100%;
    background-color: #FEFEFE;
    border-radius: 20px;
    /*padding: 26px;*/
    margin: 20px 0 0;
}

.fixing_details-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FEFEFE;
}

.edit_nav {
    display: flex;
    justify-content: space-between;
}

.edit_fix {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 150px;
    height: 100px;
    z-index: 2;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

}

.edit_fix_btn {
    width: 56px;
    height: 56px;
    background-color: #FEFEFE;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 26px rgba(41, 58, 66, 0.24);
}
.done_btn {
    overflow: hidden;
    width: 56px;
    height: 56px;
    padding: 0;
    margin: 0;
}
.done_btn > img {
    width: 250%;
}