.error-field {
    position: fixed;
    top: 24px;
    right: 24px;
    width: 600px;
    height: 100px;
    background-color: #FEFEFE;
    border-radius: 16px;
    box-shadow: 0 4px 18px 0 #38323F1A;
    transition: 0.5s;
    transform: translateX(700px);
    padding: 20px;
    z-index: 1005;
}

.error-field.active {
    transform: translateX(0px);
}

.error-field-nav {
    background-color: #FEFEFE;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.error-field-label {
    display: flex;
    align-items: center;
    background-color: #FEFEFE;
}
.error-field-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    width: 16px;
}
.error-field-img > img {
    width: 100%;
}
.error-field-label-text {
    background-color: #FEFEFE;
    color: #E05946;
    font-size: 16px;
    font-weight: 500;
}
.error-field-close-btn,
.error-field-close-btn > img{
    background-color: #FEFEFE;
    cursor: pointer;
}
.error-field-content {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    background-color: #FEFEFE;
}

.error-field-success {
    color: #789d63;
}

@media (max-width: 600px) {
    .error-field {
        width: 343px;
        top: 16px;
        right: 16px;
        box-shadow: 0 4px 18px rgba(56, 50, 63, 0.1);
        border-radius: 16px;
    }
}