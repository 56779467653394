.market-data {

}

.market-data-search {
    margin: 16px 0;
    background-color: #FEFEFE;
    border-radius: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.market-data-search-source {
    width: 160px;
    font-size: 16px;
    background-color: #FEFEFE;
    margin: 0 24px;

}

.market-data-search-field {
    width: 243px;
    position: relative;
}

.market-data-search-field.through-line .input_text-input {
    text-decoration: line-through;
}

.market-data-search-btn {
    width: 36px;
    right: 1px;
    position: absolute;
    bottom: 1px;
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    border-radius: 0 8px 8px 0;
    cursor: pointer;
}

.market_data_search_copy {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 20px;
    right: 37px;
    background: #eee;
    width: 37px;
    height: 37px;
    border-right: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    cursor: pointer;
    transition: 0.1s;
}

.market_data_search_copy:hover {
    background: #f5f5f5;
}

.market_data_search_copy > img {
    width: 14px;
    height: 14px;
}

.market-data-search-source .select_component-selected {
    padding: 9px 14px;
    font-size: 14px;
    font-weight: 400;
    position: relative;
    height: 36px;
    margin-top: 6px;
    border-radius: 8px;
}

.select_component-selected--main {
    /*border-width: 2px;*/
    /*border-color: #dadada;*/
    border: 2px solid #CFCFCF
}

.select_component-selected--change {
    border-color: #6d8eb9;
    font-style: italic;
}

.select_component-selected--change-minor {
    font-style: italic;
}

.market-data-search-source .select_component-label {
    line-height: 13px;
}
.market-data-search-source .select_component-img {
    width: 12px;
    height: 12px;
    top: 12px;
    right: 12px;
    position: absolute;
}

.market-data-search-source .select_component-img > img {
    width: 100%;
}

.market-data-search-source .select_component-list {
    top: 70px;
}

.market-data-search-source .select_component-list::-webkit-scrollbar {
    display: none;
}

.select_component-selected.disabled {
    background-color: rgba(252, 252, 252, 0.4);
    color: #949399;
}

.market-data_refresh-btn {
    /*background-color: #0080BF;*/
    background-color: #6d8eb9;
    color: #FEFEFE;
    padding: 9px 20px;
    font-size: 14px;
    /*width: 103px;*/
    display: block;
    font-weight: 500;
}

.data-id_input {
    padding: 9px 35px 9px 12px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #E3E3E5;
    border-radius: 8px;
    margin-top: 6px;
    height: 36px;
}

.data-id_input-block {
    width: 278px;
    position: relative;
}
.data-id_input-label {
    line-height: 13px;
    font-size: 12px;
    font-weight: 500;
    color: #949399;
}
.data-id_input-btn {
    position: absolute;
    top: 28px;
    right: 0;
    bottom: 0;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7E7E9;
    border-radius: 0 8px 8px 0;
}
.market-data-source-n-id {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

@media (max-width: 600px) {
    .market-data-search {
        flex-direction: column;
        align-items: stretch;
    }
    .market-data-search-field,
    .market-data-search-source {
        width: 48%;
        margin: 16px 0 0 0 ;
    }
    .data-id_input-block {
        width: 48%;
    }
}