.settings__block {
    border: 1px solid #000;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 16px;
}

.settings__block--subtitle {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}

.settings-market-data {
    display: flex;
    flex-direction: column;
}

.settings-market-data__title {
    text-decoration: underline;
}