/*html, body, #root {*/
/*    height: 100%;*/
/*}*/

.auth__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;

}

.auth__form-container {
    background: #fefefe;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    width: 400px;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
}

.form-outline {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    margin-bottom: 20px;
}

.form-control {
    padding: 9px 12px;
    font-size: 14px;
    border-radius: 12px;
    border: 1px solid #000000;
    margin-bottom: 3px;
}

.signin__btn {
    padding: 9px 12px;
    font-size: 14px;
    border-radius: 12px;
    font-weight: 600;
    border: none;
    background: #6d8eb9;
    color: #fefefe;
    cursor: pointer;
}

.signin__error {
    color: #ff0000;
    font-size: 12px;
    margin-bottom: 5px;
    text-align: center;
}

.auth__description {
    text-align: center;
    font-size: 12px;
    color: #7E8088;
    margin-top: 8px;
}