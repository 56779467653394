.simple_main_button {
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    line-height: 18px;
    padding: 9px 20px;
    background: #0080BF;
    color: #FFFFFF;
    margin-right: 6px;
    font-size: inherit;
}

.simple_secondary_button {
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    line-height: 18px;
    padding: 9px 20px;
    background: #E7E7E9;
    color: #949399;
    margin-right: 6px;
    font-size: inherit;
}

.simple_secondary_button_mod {
    color: black;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    line-height: 18px;
    padding: 9px 20px;
    background: #E7E7E9;
    margin-right: 6px;
    font-size: inherit;
}