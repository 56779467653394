.loader-block {
    width: 40px;
    height: 40px;
}

.loader-block > img {
    width: 100%;
    height: 100%;
    animation: loader 5s linear infinite;
}

@keyframes loader {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}