.ticker-hint {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    background: rgba(254, 254, 254, 0.93);
    border: 1px solid rgba(227, 227, 229, 1);
    border-radius: 8px;
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
    z-index: 100;
    max-height: 200px;
    overflow-y: scroll;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.ticker-hint-item {
    padding: 12px 9px;
    cursor: pointer;
    color: #808080;
}

.ticker-hint-item.active {
    background-color: #E7E7E9;
    border-radius: 8px;
}

.ticker-hint-item:hover {
    background-color: #E7E7E9;
    border-radius: 8px;
}
