.des__card {
    /*flex: 1;*/
    margin: 10px;
    /*min-width: 400px;*/
    /*background: #E7E7E9;*/
    border-radius: 8px;
    padding: 10px;
    height: 100%;
    /*max-height: 100%;*/
}

.des__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 10px;*/
    padding: 5px 10px;
    font-size: 14px;
    border-bottom: 1px solid #E4E4EB;
    height: auto;
}

.des__line-subtitle {
    border-bottom: 2px solid #E4E4EB;
    font-weight: 600;
}

.des__line-value {
    height: 100%;
    text-align: end;
}

.des__line-price {
    background-color: #6d8eb9;
    border-radius: 8px;
    color: white;
    padding: 2px 8px;
    cursor: pointer;
}

.des__line-img-container {
    height: 21px;
    width: 21px;
}

.des__line-img-container > img {
    height: 100%;
    cursor: pointer;
}

.des__label {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding: 5px 0;
    border-bottom: 2px solid #000;
}

.des__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.des__data {
    width: 500px;
    background-color: #fefefe;
    /*height: 500px;*/
    border-radius: 8px;
    display: flex;
    justify-content: center;
    position: relative;
    max-height: 100vh;
    overflow-y: auto;
}

.des__data__small {
    width: 250px;
}

.des__data::-webkit-scrollbar {
    display: none;
}

.des__data-card-title {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    padding-bottom: 5px;
    border-bottom: 2px solid #000;
}

.des__data-close {
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
}

.des__data-card {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.des__data-line {
    display: flex;
    font-size: 14px;
    border-bottom: 1px solid #E4E4EB;
    padding: 5px 10px;
}

.des__data-line__small {
    justify-content: space-between;
}

.des__data-line.headhead {
    font-size: 12px;
    font-weight: 600;
    color: #7E8088;
}

.des__data-line-id {
    width: 10%;
}

.des__data-line-start,
.des__data-line-end,
.des__data-line-payment {
    width: 24%;
}

.des__data-line-amount {
    width: 19%;
}

.des__data-line-rate {
    width: 9%;
}

.des__data-line-fixings {
    width: 30%;
}


@media (max-width: 420px) {
    .des__card {
        /*min-width: 100%;*/
        margin: 0 0 10px;
    }
}