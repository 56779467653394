.summary_input-fields {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
    margin-top: 10px;
}

.summary_input-field {
    width: 30%;

}

.summary-input-value {
    font-size: 14px;
    font-weight: 400;
    color: #7E8088;
}

.summary_input-field > input {
    width: 100%;
    padding: 9px 12px;
    border: 1px solid #E4E4EB;
    border-radius: 8px;
    font-size: 14px;
}

.summary_input-submit {
    background: #0080BF;
    width: 100%;
    padding: 12px 0;
    margin-top: 20px;
    border-radius: 8px;
    color: #FEFEFE;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
}

.summary-input {
    margin-bottom: 10px;
}

.calc__line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    border-bottom: 1px solid #E4E4EB;
}

/*.calc__line:nth-child(odd) {*/
/*    background-color: #f5f5f5;*/
/*}*/

.calc__label {
    font-weight: 600;
    border-bottom: 2px solid #000;
    padding-bottom: 5px;
    /*margin-bottom: 5px;*/
}

.calc__card {
    /*margin: 15px 15px 30px;*/
    width: 80%;
    background-color: #fefefe;
    border-radius: 8px;
    padding: 15px;
    font-size: 14px;
}

.calc_value,
.calc_name {
    width: 30%;
}

.calc_result {
    width: 40%;
}

.calc_result {
    text-align: right;
}

.calc__line.headhead {
    font-size: 12px;
    font-weight: 600;
    color: #7E8088;
}


.graph-table__header {
    display: flex;
    justify-content: space-between;
    padding: 10px 10px 5px;
    font-size: 14px;
    font-weight: 600;
    position: sticky;
    top: 0;
    margin: 0 10px;
    /*gap: 10px;*/
    background-color: #fefefe;
    border-bottom: 1px solid #E3E3E5;
}

.graph-table__wrapper {
    overflow-y: auto;
    width: 100%;
}
*::-webkit-scrollbar {
    display: none;
}


.graph-table__row {
    /*border-bottom: 1px solid #E3E3E5;*/
    /*margin: 10px 10px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 14px;
    width: 100%;
    /*gap: 10px;*/
}

.table-row {
    border-bottom: 1px solid #E3E3E5;
    /*border-top: 1px solid #E3E3E5;*/
    height: 100%;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    padding: 10px;
}

.graph-table__title {
    font-weight: 600;
    padding: 10px;
    font-size: 18px;
}

.graph-table__title:not(:first-child) {
    margin-top: 20px;
}