.greek__details {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.greek__block-title {
    font-size: 20px;
    font-weight: 600;
    margin: 5px 0;
    align-self: start;
}

.greek__block {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
}

.greek__table {
    width: 80%;
    height: 100%;
    align-self: flex-end;
}

.greek__table.sm {
    width: 25%;
}

.greek__table.ssm {
    width: 50%;
}

.greek__table-row.head {
    color: #949399;
    font-weight: 500;
    font-size: 14px;
}

.greek__table-row {
    border-bottom: 1px solid #E3E3E5;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    font-size: 14px;
}

.greek__table-row.tfooter {
    font-weight: 600;
}

.greek__table-row--ticker {
    width: 40%;
}

.greek__table-row--value {
    text-align: end;
    width: 30%;
}

.greek__table-row.sm {
    justify-content: center;
}

.greek__table-row.sm .greek__table-row--value {
    width: 100%;
    text-align: end;
}

.greek__table-row.ssm .greek__table-row--value {
    width: 50%;
}

.greek__table-empty {
    width: 100%;
    text-align: center;
    padding: 10px 0;
}

.greek__summary {
    width: 60%;
    margin-top: 15px;
}

@media (max-width: 768px) {
    .greek__table {
        width: 100%;
    }

    .greek__block {
        width: 100%;
    }
}