.footer {
    /*height: 86px;*/
    border-top: 1px solid rgba(227, 227, 229, 1);
    background-color: #FEFEFE;
    padding: 14px 24px;
    border-radius: 0 0 16px 16px;
}

.footer-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
}

.settings_btn {
    display: none;
}

.price_btn {
    /*background-color: #0080BF;*/
    background-color: #6d8eb9;
    color: #FEFEFE;
    padding: 9px 20px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-right: 8px;
    border-radius: 8px;
    height: 36px;
    transition: 0.1s;
}
.price_btn:active {
    background-color: #61dafb;
    color: black;
}

.pricing-result_request {
    display: block;
    width: 130px !important;
    height: 36px;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #E3E3E6;
    padding: 9px 40px 9px 12px;
}

.pricing-result_request.through-line {
    text-decoration: line-through;
}

.pricing-result_request-block {
    width: 193px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
}

.pricing-result_request-label {
    font-size: 12px;
    font-weight: 500;
    color: #949399;
    width: 60px;
}

.pricing-result_request-btn {
    position: absolute;
    top: 2px;
    right: 0;
    bottom: 2px;
    width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E7E7E9;
    border-radius: 0 8px 8px 0;
}

.price-result_block {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.button-pricing-history-footer {
    display: none;
}

.request-id__search-line {
    position: relative;
    height: 36px;
}

.request-id__search-line > .input_text {
    width: 250px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
}
.request-id__search-line > .input_text > input {
    width: 170px;
    margin: 0;
}

.request-id__search-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 36px;
    width: 36px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer;
}

.request-id__search-copy {
    position: absolute;
    top: 0;
    right: 36px;
    height: 36px;
    width: 36px;
    background-color: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #E5E5E5;
    cursor: pointer;
}


@media (max-width: 600px) {
    .footer {
        padding: 16px;
    }

    .settings_btn {
        background-color: #E7E7E9;
        border: none;
        padding: 12px 0;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        border-radius: 8px;
        display: block;
        height: 44px;
        width: 49%;
    }

    .price-nav {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer-wrapper {
        flex-direction: column;
    }

    .price_btn {
        width: 49%;
        padding: 12px 0 !important;
        font-size: 16px !important;
        height: 44px;
        margin: 0;
    }

    .pricing-result_request {
        height: 44px;
        padding: 9px 30px 9px 12px !important;
    }

    .pricing-result_request-block {
        width: 154px;
        flex-direction: column;
        align-items: start;
    }

    .pricing-result_request-label {
        margin-bottom: 6px;
    }

    .pricing-result_request-btn {
        bottom: 0;
        top: 23px;
        width: 44px;
    }

    .price-result_block {
        align-items: end;
        margin-top: 20px;
    }

    .button-pricing-history-footer {
        width: 100%;
        display: block;
        margin: 25px 0 0;
        padding: 9px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        background-color: #FEFEFE;
    }

    .request-id__search-line {
        height: 100%;
    }

    .request-id__search-line > .input_text {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
    }

    .request-id__search-line > .input_text > input {
        margin-top: 6px;
    }

    .request-id__search-btn {
        top: 19px;
        height: 39px;
        width: 39px;
    }

    .request-id__search-copy {
        top: 19px;
        height: 39px;
        width: 39px;
    }
}