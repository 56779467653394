.history__window {
    /*position: absolute;*/
    top: 50px;
    right: 0;
    background: #fefefe;
    border-radius: 8px;
    /*padding: 10px;*/
    width: 100%;
    /*max-height: 700px;*/
    overflow-y: auto;
}

.history__head {
    color: #949399;
    display: flex;
    justify-content: space-between;
    /*margin: 12px;*/
    border-bottom: 1px solid #E3E3E5;
    font-weight: 500;
    font-size: 14px;
    position: sticky;
    top: 0;
    background: #fefefe;
    margin: 0 10px;
    padding: 10px 10px 5px;
    /*border-radius: 8px;*/
}

.history__row {
    margin: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    font-size: 14px;
}

.history__row:not(:last-child) {
    border-bottom: 1px solid #E3E3E5;
}

.history__row--id {
    width: 20%;
    margin: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.history__row--id.copy,
.history__row--des.copy {
    cursor: pointer;
}

.history__row--pv {
    width: 15%;
    margin: 12px;
}

.history__row--des {
    width: 65%;
    margin: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.history__show-all {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #949399;
    padding: 5px 0;
    cursor: pointer;
}