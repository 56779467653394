.logs {
    background-color: rgba(245, 246, 247, 1);
    padding: 10px 0;
    min-height: 100vh;
}

.logs-line {
    background-color: #ffffff;
    padding: 15px 5px;
    border-radius: 8px;
    margin: 10px 0;
}