.pricing-request {
    margin: 20px 0;
    width: 520px;
    /*width: 420px;*/
}
.pricing-request-style {

}
.tickers {
    margin-bottom: 25px;
    margin-top: 8px;
    width: 100%;
    /*overflow: scroll;*/
}
.tickers::-webkit-scrollbar{
    height: 5px;
    width: 0;
}
.tickers-scroll {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.ticker-value,
.ticker-value-remove{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    padding: 6px 12px;
    border-radius: 50px;
    background-color: #F3F3F3;
    margin-right: 4px;
    height: 30px;
    margin-bottom: 4px;
    cursor: pointer;
}
.ticker-value-remove {
    background-color: #FEFEFE;
    border: 1px solid #F3F3F3;

}
.ticker-del {
    margin-left: 8px;
    border: none;
    background: none;
    font-size: 20px;
    padding: 0;
    color: #141414;
    height: 18px;
    display: flex;
    align-items: center;
}
@media (max-width: 600px) {
    .pricing-request {
        width: 100%;
    }
}