.nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    /*width: 50%;*/
}

@media (max-width: 600px) {
    .nav {
        display: none;
    }

}