.window-container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 60px);
}

.windows {
    /*width: 100%;*/
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    margin: 30px 0;
    border-radius: 8px;
    /*overflow: hidden;*/
    gap: 5px;
    height: 90%;
}

.window {
    text-align: center;
    width: calc(50% - 3px);
    height: 200px;
    /*height: 280px;*/
    background-color: #fefefe;
    border: 1px solid #6d8eb9;
    box-shadow: 0 0 4px 0 #ababab;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    font-family: Inter, sans-serif;
}

.window:hover {
    box-shadow: 0 0 8px 0 #949399;
}

.title__logo {
    box-shadow: 0 0 6px 0 #ababab;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 50px;
    border-radius: 8px;
    background-color: #ffffff;
    top: calc(100% / 2 - 25px);
    left: calc(100% / 2 - 60px);
}

.title__logo > img {
    width: 90%;
}


.top-left {
    border-top-left-radius: 8px;
}

.top-right {
    border-top-right-radius: 8px;
}

.bottom-left {
    border-bottom-left-radius: 8px;
}

.bottom-right {
    border-bottom-right-radius: 8px;
}

@media (max-width: 768px) {
    .windows {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    .window {
        width: 100%;
        height: 150px;
        border-radius: 0;
    }

    .top-left {
        order: 3;
    }

    .top-right {
        order: 2;
    }

    .bottom-left {
        order: 1;
    }

    .bottom-right {
        order: 4;
    }

    .title__logo {
        left: 5px;
        top: calc(100% / 4 - 10px);
        width: 20px;
        height: 20px;
    }

    .title__logo > img {
        width: 50%;
    }

    .second {
        top: calc(100% / 4 * 2 - 10px);
    }

    .third {
        top: calc(100% / 4 * 3 - 10px);
    }

}