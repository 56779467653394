.search_line {
    width: 540px;
    position: relative;
    /*margin-right: 40px;*/
}

.search_line > input {
    height: 40px;
    width: 100%;
    left: 0;
    top: 0;
    border-radius: 8px;
    padding: 9px 12px 9px 12px;
    background: #FEFEFE;
    border: 1px solid rgba(227, 227, 229, 1);
    font-size: 14px;
    font-weight: 400;
}

.search_line-btn {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    background: none;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search_line-btn > img {
    width: 14px;
    height: 14px;
}

.search_line-hint {
    position: absolute;
    top: 45px;
    left: 0;
    width: 100%;
    background: #FEFEFE;
    border: 1px solid rgba(227, 227, 229, 1);
    border-radius: 8px;
    padding: 10px 12px 10px 12px;
    font-size: 14px;
    font-weight: 400;
    z-index: 100;
    max-height: 200px;
}

@media (max-width: 600px) {
    .search_line {
        /*margin-left: 16px;*/
        width: 100%;
    }
}