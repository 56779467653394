.main {
    min-height: calc(100vh - 70px);
}

.main-wrapper {
    width: 100%;
}

.main-container {
    width: 100%;
    height: 100%;
    margin-top: 16px;
    position: relative;
}

.main-content {
    width: 640px;
}

.main-content-selection {
    padding: 20px 24px 20px;
    width: 640px;
    background-color: #fefefe;
    border-radius: 16px 16px 0 0;
}

.button-pricing-history {
    /*padding: 8px 20px !important;*/
    position: absolute;
    right: 17px;
    top: 17px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /*margin-top: 10px;*/
}

.button-pricing-history > img {
    width: 60%;
}

.button-pricing-history.button {
    background-color: #fefefe;
    border: 1px solid #6d8eb9;
}

.product-selector-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.settings_desktop {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.settings_img {
    width: 100%;
}

.mini-history {
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% - 660px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-height: 100%;
}

.setting_word {
    display: none;
}

@media (max-width: 768px) {
    .settings_desktop {
        display: none;
    }

    .main-container > .button-pricing-history {
        display: none;
        position: static;
    }

    .main-content-selection,
    .main-content {
        width: 100%;
    }

}

@media (max-width: 600px) {
    .main-wrapper {
        padding: 0;
    }

    .main-container {
        border-radius: 0;
        width: 100%;
    }

    .main-content {
        padding: 0;
    }

    .main-content-selection {
        padding: 16px;
    }

    .product-selector {
        padding-bottom: 0 !important;
    }

    .product-nav {
        margin: 16px 0 20px 0 !important;
    }
}