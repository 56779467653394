.modal {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(20, 20, 20, 0.6);
    z-index: 2;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    overflow: scroll;
}

.modal-content {
    background-color: #E7E7E9;
    width: 100%;
    height: 500px;
    border-radius: 50px 50px 0 0;
    min-height: calc(100vh - 71px);
}

.modal.active {
    display: flex;
}
@media (max-width: 768px) {
    .modal {
        padding: 0;
        margin: 0;
    }
}