.snapshot-btn__container {
    width: 40px;
    height: 40px;
    background-color: #fefefe;
    border-radius: 8px;
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.snapshot-btn__container img {
    width: 50%;
    height: 50%;
}