.settings {
    padding: 16px;
}

.settings--title {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 16px;
}

.settings--label > input {
    margin-left: 8px;
}