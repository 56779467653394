.pricing-history {
    width: 100%;
    height: 100%;
    /*background-color: rgba(20, 20, 20, 0.6);*/
    position: relative;

    overflow-x: hidden;
    overflow-y: hidden;
    /*padding: 0 16px;*/
}

.pricing-history-close {
    position: absolute;
    top: 8px;
    right: 0px;
    cursor: pointer;
    background-color: rgba(254, 254, 254, 0.3);
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

}

.pricing-history-clear {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: #ffffff;
    padding: 3px 5px;
    border-radius: 8px;
    cursor: pointer;
}

.history-line {
    width: 400px;
    padding: 10px;
}

.pricing-history_main-view {
    /*margin-top: 50px;*/
    padding: 0 24px;
    width: 100%;
    height: calc(100vh - 50px);
    background-color: #fefefe;
    /*background-color: #F3F3F3;*/
    border-radius: 20px 20px 0 0;
    overflow: scroll;
    position: relative;
}
.market-table-downloads_pricing-history {
    position: absolute;
    top: 35px;
    left: 220px;
    display: flex;
}
.download-history {
    position: absolute;
    top: 0;
    right: 0;
}

.pricing-history_main-view::-webkit-scrollbar {
    display: none;
}

.pricing-history_title {
    margin-top: 24px;
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 15px;
}

.pricing-history_table {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /*overflow-x: scroll;*/
}

.pricing-history_table::-webkit-scrollbar {
    display: none;
}

.pricing-history_table-head {
    display: flex;
    position: sticky;
    top: 0;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #E3E3E5;
    /*background-color: #F3F3F3;*/
    background-color: #fefefe;
}

.pricing-history_table-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pricing-history_table-row:not(:last-child) {
    border-bottom: 1px solid #E3E3E5;
}

.ptable-head {
    margin: 12px;
    color: #949399;
    text-align: end;
    font-size: 14px;
    font-weight: 500;

}

.ptable-row {
    margin: 12px;
    font-size: 14px;
    font-weight: 400;
    text-align: end;
}

.ptable-payoff {
    width: 66px;
    text-align: start;
}

.ptable-cpnamt {
    width: 66px;
}

.ptable-cpn {
    width: 66px;
}

.ptable-ac {
    width: 66px;
}

.ptable-sf {
    width: 66px;
}

.ptable-t2m {
    width: 66px;
}

.ptable-expiry {
    width: 84px;
}

.ptable-trading {
    width: 84px;
}

.ptable-currency {
    width: 76px;
    text-align: start;
}

.ptable-tickers {
    width: 206px;
    text-align: start;
}

.ptable-wrapper {
    width: 86px;
    text-align: start;
}

.ptable-pv {
    width: 76px;
}

.ptable-number {
    width: 25px;
}

.ptable-request-id {
    width: 75px;
}

.ptable-row.ptable-request-id {
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    color: #0080BF;
}
.ptable-row.ptable-request-id:hover {
    text-decoration: underline;
}

.ptable-description {
    text-align: start;
    flex: 1;
    overflow: scroll;
}

.clear-localstorage {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background: #FFFFFF;
    box-shadow: 0 4px 26px rgba(41, 58, 66, 0.24);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clear-localstorage > img {
    width: 50%;
    height: 50%;
}

@media (max-width: 600px) {
    .pricing-history_main-view {
        margin: 0 !important;
        height: 100%;
        border-radius: 0;
    }

    .pricing-history-close {
        background: none;
        right: 12px;
        top: 12px;
        z-index: 2;
    }
}