.fixing_detail_desktop-details__header-item {
    margin-top: 16px;
}

.fixing_detail_desktop-actions {
    display: flex;
}

.fixing_detail_desktop-actions__item {
    font-size: 14px;
}

.fixing_detail_desktop-details__body {
    margin-bottom: 10px;
}

.fixing_detail_desktop-details__header,
.fixing_detail_desktop-details__body-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.fixing_detail_desktop-details--selector {
    width: 20px;
    padding-top: 8px;
    margin-right: 16px;
}

.fixing_detail_desktop-details--pricing {
    width: 115px;
    margin-right: 16px;
}

.fixing_detail_desktop-details--ac,
.fixing_detail_desktop-details--cpn {
    width: 100px;
    margin-right: 16px;
}

.fixing_detail_desktop-details__header-item {
    font-weight: 600;
    font-size: 14px;
    color: #949399;
}

.fixing_detail_desktop-open_img {
    width: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixing_detail_desktop-open_img img {
    width: 90%;
}