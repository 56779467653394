.market_data_window {
    overflow: scroll;
    width: 100%;
}

.market_data_window::-webkit-scrollbar {
    display: none;
}

.market_data_window-header {
    height: 20px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    color: #949399;
}

@media (max-width: 768px) {
    .market_data_window-scroll {
        width: 100%;
        overflow: scroll;
    }

    .market_data_window-scroll::-webkit-scrollbar {
        display: none;
    }

    .market_data_window {
        width: 550px;
    }
}