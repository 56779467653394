.multigraph-area {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.multigraph-area__chart {
    height: 40vh;
    max-width: 50%;
    width: 100%;
}