.product-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media (max-width: 768px) {
    .product-nav {
        margin: 20px 0;
        overflow: scroll;
    }
    .product-nav::-webkit-scrollbar {
        display: none;
    }
}