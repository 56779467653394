.select-field {
    /*background-color: #7c3f3f;*/
    /*box-shadow: 0 4px 18px 0 rgba(56, 50, 63, 0.1);*/
    isolation: isolate;
    background: rgba(254, 254, 254, 1);
    width: 343px;
    padding: 7px;
}

.select-field-line  {
    font-size: 14px;
    font-weight: 400;
    padding: 9px 12px;
    background-color: rgba(254, 254, 254, 1);
    border-radius: 8px;
    cursor: pointer;
}

.select-field-line:hover {
    background-color: rgba(244, 243, 245, 1);
}

.select-field-line.selected{
    background-color: rgba(244, 243, 245, 1);
}