.link {
    padding: 10px 16px;
    display: block;
    text-decoration: none;
    color: #fefefe;
    font-weight: 600;
    border-radius: 8px;
    border: 1px solid #fefefe;
    width: 85px;
    text-align: center;
}
.link.active {
    /*border-radius: 8px;*/
    background: #fefefe;
    color: black;
    /*background: #E7E7E9;*/
}

@media (max-width: 768px) {
    .link {
        border: none;
    }
}
