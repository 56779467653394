.input_number {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;
    /*font-size: 12px;*/
    font-size: 10px;
    color: #949399;
    line-height: 13px;
    height: 100%;
}

.input_number-input {
    width: 100%;
    padding: 9px 12px;
    font-weight: 400;
    background-color: #FEFEFE;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1px;
    height: 100%;
}

.input_number-input--main {
    border: 2px solid #CFCFCF;
}

.input_number-input--changed {
    border: 2px solid #6d8eb9;
    font-style: italic;

}

.input_number-input--changed-minor {
    font-style: italic;
}

.input_number-input.input_number-input--with_label {
    margin-top: 6px;
}

.input_number-input::-webkit-inner-spin-button {
    display: none;
}