.container {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
}

@media (max-width: 1200px) {
    .container {
        max-width: 900px;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 600px;
    }
}

@media (max-width: 600px) {
    .container {
        max-width: 500px;
    }
}