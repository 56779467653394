.graph_vol {
    width: 100%;
    display: flex;
    position: relative;
    height: 100%;
}

.graph_vol-property {
    width: 290px;
    height: calc(100% - 60px);
    overflow: scroll;
}

.graph_vol-property-ticker {
    width: 110px;
}

.graph_vol-property-type {
    width: 120px;
}

.graph_vol-property-type-label {
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10px;
}

.graph_vol-property-type-nav {
    display: flex;
    justify-content: space-between;
}

.graph_vol-property-type-btn {
    width: 36px;
    height: 36px;
    padding: 14px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.graph_vol-property-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.graph_vol-property-block {
    padding: 24px 24px 10px;
}

.graph_vol-graph {
    flex-grow: 2;
    height: 100%;
    background-color: black;
    overflow: scroll;
}

.graph_vol-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 290px;
    height: 60px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-top: 1px solid #E5E5E5;
    padding: 10px;
}

@media (max-width: 768px) {
    .graph_vol {
        flex-direction: column;
        height: auto;
    }
    .graph_vol-property {
        height: 100%;
        overflow: visible;
    }

    .graph_vol-property {
        width: 100%;
    }
    .graph_vol-graph {
        height: 450px;
    }

    .graph_vol-actions {
        position: relative;
        width: 100%;
        height: 60px;
        /*background-color: #949399;*/

    }
    .graph_vol-property-block {
        padding: 35px 24px 10px;
    }
    .graph_vol-property-ticker {
        flex-grow: 2;
        margin-right: 16px;
    }
}