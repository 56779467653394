.chart-history_window {
    width: 100%;
    /*height: 100%;*/
    /*overflow: hidden;*/
    /*padding: 0 15px;*/
    display: flex;
    flex-direction: column;
    margin-top: 10%;
}

.chart-history_status {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.chart-history_status img {
    width: 60%;
}

.chart-history_title {
    color: #949399;
    font-weight: 600;
    font-size: 14px;
}

.chart-history_header {
    margin: 10px 0 0 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chart-history_all-btn {
    color: #949399;
    font-weight: 600;
    font-size: 14px;
    border: 1px solid #0080BF;
    padding: 3px 5px;
    border-radius: 8px;
    cursor: pointer;
}

.chart-history_table {
    /*background-color: #fefefe;*/
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 3px;
    padding: 3px;
    border-radius: 8px;
    height: 100%;
    /*flex-grow: 2;*/
    overflow: scroll;
}

.chart-history_table::-webkit-scrollbar {
    display: none;
}

.chart-history_row {
    padding: 10px 0;
    font-size: 12px;
    cursor: pointer;
    max-height: 100%;
    width: 47%;
    border-bottom: 1px solid #E3E3E5;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chart-history_row:hover {
    background-color: #F5F6FA;
    border-radius: 8px;
}

.chart-history_show {
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #949399;
    padding: 5px 0;
    cursor: pointer;
}

.chart-history_divider {
    height: 10px;
    background-color: #fefefe;
}

.chart-history_table_full {
    justify-content: space-between;
    padding: 20px;
}

.chart-history_row_full {
    text-align: center;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: space-between;*/
}