.result_field {
    display: flex;
    align-items: center;
    /*width: 40px;*/
    height: 40px;
}
.result_field-title {
    color: rgba(148, 147, 153, 1);
    cursor: default;
    font-size: 14px;
    font-weight: 400;
    margin: 0 22px;
}
.result_field-value {
    /*margin: 0 22px;*/
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    padding: 8px;
    border-radius: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 100%;
    overflow: hidden;
}
.result_field-value:hover {
    background-color: rgba(227, 227, 229, 1);
}
.result_field-value::after:hover {
    content: '123';
}

@media (max-width: 600px) {
    .result_field-title {
        margin: 0 12px 0 0;
        font-size: 16px;
    }
    .result_field-value {
        padding: 0;
        font-size: 26px;
    }
    .result_field {
        margin-bottom: 5px;
    }
}