.nav-select {
    display: flex;
    padding: 4px;
    background-color: rgba(231, 231, 233, 1);
    border-radius: 33px;
}
.nav-select-option {
    padding: 7px 14px;
    border-radius: 32px;
    background-color: rgba(231, 231, 233, 1);
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.nav-select-option.selected {
    background-color: rgba(254, 254, 254, 1);
}

@media (max-width: 768px) {

    .nav-select {
        background-color: #FEFEFE;
        align-items: center;
    }
    .nav-select-option {
        font-weight: 400;
        text-align: center;
    }
    .nav-select-option:not(:last-child) {
        margin-right: 4px;
    }
    .nav-select-option.selected {
        /*background-color: #0080BF;*/
        background-color: #6d8eb9;
        color: #FEFEFE;
    }
}

@media (max-width: 360px) {
    .nav-select-option {
        font-size: 13px;
        font-weight: 400;
        text-align: center;
    }
}