.pricing-request_date-block {
    display: flex;
}

.pricing-request_date-block__item {
    width: 120px;
    margin-right: 10px;
}

.pricing-request-search {
    /*background-color: #FEFEFE;*/
    border-radius: 20px;
    /*padding: 26px;*/
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    /*margin-bottom: 6px;*/
}

.pricing-request-input {
    padding: 9px 12px;
    font-size: 14px;
    font-weight: 400;
    background-color: #FEFEFE;
    border: 1px solid #E3E3E5;
    border-radius: 10px;
    /*height: 50px;*/
}

.pricing-request-input::-webkit-calendar-picker-indicator {
    opacity: 0;
}

.pricing-request-input-block {
    width: 116px;

    margin-right: 10px;
}

.pricing-request-input-label {
    display: block;
    font-weight: 500;
    font-size: 12px;
    color: #949399;
    margin-bottom: 6px;
}

@media (max-width: 600px) {
    .pricing-request-search {
        order: -1;
        margin-bottom: 16px;
    }

    .pricing-request_date-block {
        width: 100%;
        justify-content: space-between;
    }

    .pricing-request-input-block {
        width: 48%;
    }

    .pricing-request-input-block {
        margin: 0;
    }
    .pricing-request_date-block__item {
        width: 49%;
    }
}