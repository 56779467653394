.input_graph {
    width: 100%;
    height: 100%;
}

.input_graph-wrapper {
    position: relative;
    width: 100%;
}

.input_graph-field {
    width: 100%;
    padding: 9px 40px 9px 12px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    border-radius: 8px;
    border: 1px solid #E5E5E5;
}

.input_graph-field--changed {
    border: 1px solid #4781fc;
}

.input_graph-btn {
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #F3F3F3;
    border-radius: 0 8px 8px 0;
}