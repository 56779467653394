.header {
    height: 60px;
    /*background-color: rgba(245, 246, 247, 1);*/
    background: #6d8eb9;
}

.header_wrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    /*border-bottom: 1px solid #E3E3E5;*/
    height: 100%;
    width: 100%;


}
.header_wrapper-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_wrapper-content-main {
    display: flex;
    align-items: center;
    width: 100%;
}

@media (max-width: 768px) {
    .header_wrapper-content {
        padding: 0 10px 0 16px;
    }
}

@media (max-width: 600px) {
    .header {
        /*background: linear-gradient(270deg, #829FC5 0%, rgba(136, 163, 200, 0) 100%);*/
        background-color: #6d8eb9;
    }
    /*.header_wrapper-content {*/
    /*    flex-direction: column;*/
    /*    align-items: stretch;*/
    /*}*/
}