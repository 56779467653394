.graph-table__head {
    color: #949399;
    display: flex;
    justify-content: space-between;
    /*margin: 12px;*/

    font-weight: 500;
    font-size: 14px;
    position: sticky;
    top: 0;
    margin: 0 10px;
    padding: 10px 10px 5px;
    /*border-radius: 8px;*/
    gap: 10px;
}

.graph-table__row {
    /*border-top: 1px solid #E3E3E5;*/
    margin: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*padding: 5px 10px;*/
    font-size: 14px;
}