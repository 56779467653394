.input_date {
    font-weight: 500;
    /*font-size: 12px;*/
    font-size: 10px;
    color: #949399;
    line-height: 13px;
    display: block;
}

.input_date-input-with-icon,
.input_date-input {
    width: 100%;
    padding: 9px 12px;
    font-weight: 400;
    background-color: #FEFEFE;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    font-size: 14px;
    margin-top: 6px;
    /*height: 39px;*/
    height: 100%;
}

.input_date-input-with-time {
    font-size: 12px;
    height: 40px;
    /*width: 45%;*/
}

.input_date-input-with-icon {
    background-image: url("./../../static/calendar.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.input_date-input-with-icon::-webkit-calendar-picker-indicator {
    opacity: 0;
}

.input_date-input::-webkit-calendar-picker-indicator {
    display: none;
}


/*.input_date-input::-webkit-inner-spin-button {*/
/*    display: none;*/
/*}*/