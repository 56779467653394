.loader-window {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(20, 20, 20, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.loader-window_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
}

.loader-window_content-image {
    width: 150px;
}

.loader-window_content-image > img {
    width: 100%;
    animation: loader-window 5s linear infinite;
}

.loader-window_content > h3 {
    color: #fefefe;
}

@keyframes loader-window {
    from {
        transform: rotateZ(0deg);
    }

    to {
        transform: rotateZ(360deg);
    }
}