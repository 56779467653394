.product-selector {
    font-weight: 600;
    font-size: 26px;
    padding-bottom: 16px;
    position: relative;
    display: flex;
}
.product-selector-selected {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.product-selector-selected:hover {
    color: #0080BF;
}

.product-selector-img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7.5px;
}

.product-selector-listener {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(131, 134, 142, 0);
    display: none;
    z-index: 1;
}
.product-selector-listener.active {
    display: block;
}

.product-selector-all_product {
    border-radius: 10px;
    visibility: hidden;
    max-height: 285px;
    overflow: scroll;
    background: #FEFEFE;
    position: absolute;
    z-index: 2;
    top: 40px;
    overflow-x: hidden;
    /*overflow-y: hidden;*/
    box-shadow: 0 4px 18px 0 rgba(56, 50, 63, 0.1);
}

.product-selector-all_product.active {
    visibility: visible;
}

