.nav-burger {
    width: 56px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.menu-burger {
    width: 21px;
    height: 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.burger-line {
    width: 100%;
    height: 2px;
    background-color: #FEFEFE;
}
.nav-mobile {
    padding-top: 70px;
    width: 100%;
    height: 100%;
    /*background: linear-gradient(328.98deg, #1273CD 23.91%, rgba(46, 125, 199, 0) 99.59%);*/
    /*background-color: #0080BF;*/
    background: #6d8eb9;
    font-size: 40px;
    font-weight: 600;
    position: relative;
}
.close-nav {
    position: absolute;
    top: 19px;
    right: 19px;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.close-nav > img {
    width: 100%;
}
.nav-mobile .link {
    color: #FEFEFE;
    opacity: 0.57;
}
.nav-mobile .link.active {
    background: none;
    opacity: 1;
}