.market_data_window-header {

}

.market_data_line {
    display: flex;
    margin-top: 6px;
    height: 39px;
}

.market_data_line--name,
.market_data_line--spot,
.market_data_line--ref_spot {
    width: 86px;
    height: 100%;
    margin-right: 16px;
}


.market_data-checkbox {
    width: 86px;
    height: 100%;
    display: flex;
}

.market_data-checkbox p {
    margin-right: 5px;
}


.market_data_line--vol,
.market_data_line--div {
    width: 116px;
    margin-right: 16px;
    /*height: 39px;*/
}