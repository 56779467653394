.market_table {
    width: 100%;
    background-color: #FEFEFE;
    margin-top: 24px;
}

.market-table-input-block {
    display: flex;
    background-color: inherit;
    margin: 20px 0;
}

.market-table-input {
    /*width: 150px;*/
    margin-right: 22px;
    background-color: #FEFEFE;
}

.market-table-input-element {
    background-color: #FEFEFE;
    border: 1px solid #E7E7E9;
    font-size: 14px;
    font-weight: 400;
    padding: 9px 12px;
    border-radius: 8px;
}

.market-table-input-element::-webkit-inner-spin-button {
    display: none;
}

.market-table-input-element-block {
    background-color: #FEFEFE;
    width: 80px;
}

.market-table-input-element-label {
    background-color: #FEFEFE;
    font-size: 12px;
    font-weight: 500;
    color: #949399;
    margin-bottom: 10px;
    display: block;
}

.market-table-content {
    background-color: #FEFEFE;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.market_table-header,
.market-table-line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #FEFEFE;
    width: 100%;
}

.market_table-ticker {
    width: 130px;
    height: 18px;
    margin: 15px 12px;
    background-color: #FEFEFE;
    /*flex-grow: 2;*/
}

.market-table-ticker-header {
    font-size: 14px;
    font-weight: 600;
    color: #949399;
    /*width: 130px;*/
    /*padding: 15px 10px;*/
}

.market_table-value {
    width: 66px;
    height: 32px;
    background-color: #FEFEFE;
    margin: 5px 10px;
    text-align: start;
    /*flex-grow: 1;*/
}

.market-table-value-header {
    font-size: 14px;
    font-weight: 500;
    color: #949399;
    /*flex-grow: 1;*/
}


.market_table-corr_mtx-field {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    width: 66px;
}

.matrix-input-block {
    width: 66px;
    margin: 5px 10px;
}

.market-table_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
}

.market-data-table {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}

.market-table-downloads-text {
    font-size: 14px;
    font-weight: 600;
    color: #0080BF;
    margin-left: 6px;
    text-decoration: none;
}

.market-table-downloads {
    display: flex;
    align-items: center;
    cursor: pointer;
}

/*
        New styles
 */

.market_table-section-title {
    font-size: 18px;
    font-weight: 600;
    margin: 20px 0;
    display: flex;
}

.market_table-section-title p {
    margin-right: 10px;
}

.market_table-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 20px 0;
}

.market_table-section-content-field {
    width: 80px;
    margin-right: 20px;
}

.market_table-section-content-table {
    display: flex;

}

.market_table-section-content {
    width: 100%;
    overflow: scroll;
}

.market_table-section-content::-webkit-scrollbar {
    display: none;
}

@media (max-width: 600px) {
    .market-table {
        overflow: scroll;
        margin-top: 54px;
    }

    .market-table-content {
        width: 592px;
    }

    .market-table-ticker {
        margin: 14px 12px;
    }

    .market-table-content-scroll {
        width: 100%;
        overflow: scroll;
    }

    .market_table-section-content-field {
        margin-right: 10px;
    }
}