body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  /*background: #E5E5E5;*/
  /*background-color: rgba(245, 246, 247, 1);*/
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Hind Madurai', sans-serif;
}

body {
  /*background: radial-gradient(circle, #40A9DD 0%,#F5F6F7 70% ,#F5F6F7 100%);*/
  /*background-size: 100% 200%;*/
  /*background-position: 100vw 100vh;*/
  /*background-repeat: round;*/
  background-image: radial-gradient(
          farthest-corner at 100vw 150vh,
          /*#40A9DD 0%,*/
          #6d8eb9 0%,
          #F5F6F7 70%,
          #F5F6F7 100%
  );
  /*background: #F5F6F7;*/
}

@media (max-width: 600px) {
  body {
    background: none;
  }
}